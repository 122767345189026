import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [0];

export const dictionary = {
		"/[[lang=lang]]/(app)": [10,[2,3]],
		"/[[lang=lang]]/access-denied": [37,[2]],
		"/[[lang=lang]]/(app)/admin/local-authorities": [~13,[2,3,4]],
		"/[[lang=lang]]/(app)/admin/service-announcements": [14,[2,3,4]],
		"/[[lang=lang]]/(app)/admin/user-registration": [~15,[2,3,4]],
		"/[[lang=lang]]/(app)/admin/users": [16,[2,3,4]],
		"/[[lang=lang]]/(app)/admin/users/invited": [20,[2,3,4]],
		"/[[lang=lang]]/(app)/admin/users/invite": [~19,[2,3,4]],
		"/[[lang=lang]]/(app)/admin/users/[id]": [17,[2,3,4,5]],
		"/[[lang=lang]]/(app)/admin/users/[id]/roles": [18,[2,3,4,5]],
		"/[[lang=lang]]/cookies": [38,[2]],
		"/[[lang=lang]]/(app)/(help-area)/faq": [~11,[2,3]],
		"/[[lang=lang]]/(app)/form/(group)/document-request": [21,[2,3]],
		"/[[lang=lang]]/(app)/form/(group)/feedback": [22,[2,3]],
		"/[[lang=lang]]/(app)/form/group": [26,[2,3]],
		"/[[lang=lang]]/(app)/form/(group)/myepay": [23,[2,3]],
		"/[[lang=lang]]/(app)/form/(group)/payroll": [24,[2,3]],
		"/[[lang=lang]]/(app)/form/[formKind=formKind]": [~25,[2,3]],
		"/[[lang=lang]]/(app)/(help-area)/help": [~12,[2,3]],
		"/[[lang=lang]]/(app)/investigations/[id]": [27,[2,3,6]],
		"/[[lang=lang]]/(app)/investigations/[id]/[submissionID]/raw": [28,[2,3,6,7]],
		"/[[lang=lang]]/(app)/investigations/[id]/[submissionID]/summary": [29,[2,3,6,7]],
		"/[[lang=lang]]/invite/[id]": [39,[2]],
		"/[[lang=lang]]/login": [~40,[2]],
		"/[[lang=lang]]/policies/[policy=policy]": [~41,[2]],
		"/[[lang=lang]]/register-interest": [~42,[2]],
		"/[[lang=lang]]/(app)/submissions": [30,[2,3]],
		"/[[lang=lang]]/(app)/submissions/all": [35,[2,3]],
		"/[[lang=lang]]/(app)/submissions/(assigned)/assigned": [33,[2,3,8]],
		"/[[lang=lang]]/(app)/submissions/[form=formKind]": [34,[2,3]],
		"/[[lang=lang]]/(app)/submissions/(assigned)/[id=submissionID]": [31,[2,3,8,9]],
		"/[[lang=lang]]/(app)/submissions/(assigned)/[id=submissionID]/convert": [~32,[2,3,8,9]],
		"/[[lang=lang]]/(app)/submit-as": [~36,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';